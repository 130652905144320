/* General Resets and Font Definitions */
body, h1, h2, h3, h4, h5, h6, p, ul, li, a, button {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    text-decoration: none;
    list-style: none;

}

body {
    background-color: black; /* Dark mode background color */
    color: #E0E0E0; /* Soft white text color for readability */
    line-height: 1.6; /* Default line height */
}

/* Header Styles */
.header {
    background-color: black;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Ensure wrapping on small screens */
}

.logo {
    height: 60px;
}

.site-title {
    font-size: 1.5rem;
    color: #FFFFFF;
    margin-left: 10px;
}

/* Navigation Styles */
.navigation {
    display: flex;
    gap: 10px;
}

.nav-link {
    padding: 8px 16px;
    border-radius: 8px;
    color: #E0E0E0;
    background-color: black; /* Default state */
    font-size: 0.9rem;
}

.nav-link.active, .nav-link:hover {
    color: #FFFFFF;
    background-color: #007AFF; /* Highlighted state for active/hover */
}

/* Game List Styles */
.game-list-container {
    max-width: 700px;
    margin: 20px auto;
    padding: 20px;
    background-color: #2C2C2E;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.5);
}

.game-list-title {
    font-size: 2rem;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.filters {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

.filters input {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #3A3A3C;
    background-color: #2C2C2E;
    color: #E0E0E0;
    font-size: 0.9rem;
}

/* Score and Team Styles */
.list-group-item {
    background-color: #1C1C1E;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.score-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.team-name {
    font-size: 1rem;
    font-weight: 600;
    color: #FFFFFF;
}

.game-score {
    background-color: #007AFF;
    color: #FFFFFF;
    border-radius: 20px;
    padding: 8px 16px;
    font-size: 1.2rem;
    font-weight: bold;
}

.game-date {
    font-size: 0.8rem;
    color: #A8A8A8;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .header {
        padding: 10px;
    }

    .site-title {
        margin-left: 0;
        margin-top: 10px;
    }

    .navigation {
        width: 100%;
        justify-content: space-around;
    }

    .nav-link {
        flex-grow: 1;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .logo {
        height: 50px;
    }

    .site-title {
        font-size: 1.25rem;
    }

    .filters input {
        font-size: 0.85rem;
    }

    .game-list-title {
        font-size: 1.5rem;
    }

    .team-name,
    .game-score {
        font-size: 1rem;
    }

    .game-date {
        font-size: 0.75rem;
    }
}

/* Premium Button */
.pro-button {
    background: linear-gradient(45deg, #FFD700, #FFA500); /* Gold gradient for premium look */
    color: #0A0A0A; /* Dark text for contrast */
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 20px; /* More pronounced rounded corners for a badge-like look */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: box-shadow 0.3s, transform 0.3s;
    position: relative; /* Required for pseudo-element */
    overflow: hidden; /* Keeps pseudo-element within the button bounds */
}

.pro-button:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px); /* Slight lift effect */
}



.pro-button:hover::after {
    right: 10px; /* Moves star into view on hover */
}


/* General Resets and Font Definitions */
/* ... */

/* Header Styles */
.header {
    background-color: black;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.logo {
    height: 60px;
    display: flex;
    align-items: center;
}

.site-title {
    font-size: 1.5rem;
    color: #FFFFFF;
    padding-left: 10px; /* Ensure spacing between logo and title */
}

/* ... */

/* Navigation Styles */
/* ... */

/* Game List Styles */
/* ... */

/* Score and Team Styles */
/* ... */

/* Responsive Adjustments */
@media (max-width: 768px) {
    /* ... */
}

@media (max-width: 480px) {
    .header {
        justify-content: center;
        padding: 10px 0;
    }

    .logo, .site-title {
        height: auto;
        margin-bottom: 5px;
    }

    .logo {
        order: 1; /* Logo comes first */
        height: 50px; /* Adjust height for mobile */
    }

    .site-title {
        order: 2; /* Site title comes second */
        font-size: 1.25rem; /* Adjust font size for mobile */
        padding-left: 0; /* Remove padding when on mobile */
        margin-bottom: 5px; /* Space between title and nav */
    }

    .navigation {
        order: 3;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }

    .nav-link {
        flex-grow: 1;
        text-align: center;
        padding: 8px; /* Reduce padding on smaller screens */
    }

    .pro-button {
        order: 4; /* Ensure the button comes last */
        width: 100%; /* Full width button */
        margin: 10px 0; /* Add some margin to the top and bottom */
        font-size: 0.9rem; /* Adjust font size for mobile */
        padding: 10px; /* Adjust padding for mobile */
    }

    .pro-button::after {
        font-size: 18px; /* Adjust star size for mobile */
        top: 12px; /* Center vertically */
        right: 12px; /* Center horizontally */
    }
}

/* ... */
/* Header Styles */
.header {
    background-color: black; /* Dark mode header background */
    padding: 10px 20px; /* Adjusted padding for spacing */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo-and-title {
    display: flex;
    align-items: center;
}

.logo {
    height: 50px; /* Adjusted for better fit */
    width: auto; /* Maintain aspect ratio */
    margin-right: 10px; /* Space between logo and title */
}

.site-title {
    font-size: 1.8rem; /* Larger font size for visibility */
    color: #FFFFFF; /* White color for contrast */
    font-weight: bold; /* Bold font weight for impact */
    display: inline-block; /* Inline-block for proper alignment */
}

/* Rest of the styles remain the same */

/* Responsive Adjustments */
@media (max-width: 768px) {
    .header {
        flex-direction: row; /* Keep in a row on smaller screens */
        flex-wrap: nowrap; /* Prevent wrapping */
        padding: 10px 15px; /* Slightly less padding on smaller screens */
    }

    .logo-and-title {
        flex-grow: 1; /* Allow logo and title to fill the space */
        justify-content: flex-start; /* Align to the start */
    }

    .logo {
        height: 40px; /* Slightly smaller logo on smaller screens */
    }

    .site-title {
        font-size: 1.5rem; /* Slightly smaller font size on smaller screens */
    }
}

@media (max-width: 480px) {
    /* Further adjustments for very small screens if necessary */
    .header {
        padding: 8px 10px; /* Reduced padding */
    }

    .logo {
        height: 35px; /* Even smaller logo for very small screens */
    }

    .site-title {
        font-size: 1.2rem; /* Reduced font size for very small screens */
    }
}
/* General Resets and Font Definitions */
/* ... */

/* Header Styles */
.header {
    background-color: black;
    padding: 10px; /* Adjust padding to ensure it fits on mobile */
    display: flex;
    align-items: center;
    justify-content: space-between; /* Adjust spacing */
    flex-wrap: wrap;
}

.logo {
    height: 40px; /* Adjust logo size to fit on mobile */
    margin-right: 10px;
}

.site-title {
    font-size: 1.2rem; /* Adjust title size for mobile */
    color: #FFFFFF;
}

/* Navigation Styles */
/* ... */

/* Game List Styles */
/* ... */

/* Score and Team Styles */
/* ... */

/* Responsive Adjustments */
@media (max-width: 768px) {
    .header {
        justify-content: center; /* Center items for better spacing */
        flex-direction: column; /* Stack header items */
        text-align: center; /* Center text */
    }

    .logo {
        margin-bottom: 5px; /* Add some space between logo and title */
    }

    .site-title {
        margin-bottom: 10px; /* Add some space between title and navigation */
    }

    .navigation {
        width: 100%;
        justify-content: center;
    }

    .nav-link {
        flex-grow: 1;
        text-align: center;
    }

    .pro-button {
        width: 100%; /* Full width for pro button on mobile */
        margin-top: 10px; /* Space above the pro button */
    }
}

@media (max-width: 480px) {
    /* Further adjustments for very small screens if necessary */
    .logo, .site-title {
        /* Adjust sizes if necessary for smaller devices */
    }
}

/* Existing General Styles for .pro-button */
.pro-button {
    /* ... existing styles ... */
    position: relative; /* Needed for the pseudo-element animation */
    overflow: hidden; /* Ensures pseudo-elements stay within the button bounds */
}

/* Glistening effect */
.pro-button::after {
    content: '';
    position: absolute;
    top: -50%; 
    left: -50%;
    width: 200%; 
    height: 200%; 
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%); /* Light glistening effect */
    transform: rotate(30deg); /* Angle of the glistening effect */
    animation: glisten 3.5s infinite linear; /* Animation for the glistening effect */
}

/* Keyframes for glistening animation */
@keyframes glisten {
    0% {
        transform: translateX(-50%) rotate(30deg);
    }
    100% {
        transform: translateX(150%) rotate(30deg);
    }
}

/* Responsive Adjustments */
@media (max-width: 480px) {
    .pro-button::after {
        /* Adjustments for the glistening effect on smaller screens if necessary */
    }
}

/* Responsive Adjustments for Mobile */
@media (max-width: 480px) {
    .header {
        justify-content: space-around; /* Distribute space evenly around items */
        padding: 10px 0; /* Adjust padding for mobile header */
    }

    .header > * { /* Target all direct children of .header */
        flex: 1 1 25%; /* Allow flex items to grow and shrink but base it on 25% of the container's size */
        text-align: center; /* Center-align the text for all items */
        padding: 5px; /* Adjust padding to ensure content fits well */
        box-sizing: border-box; /* Include padding and border in the element's total width and height */
    }

    .logo, .site-title, .navigation, .pro-button {
        margin: 0; /* Remove any margin that could affect the width */
    }

    .logo img { /* Assuming the logo is an image */
        max-width: 100%; /* Ensure the image is responsive and fits its container */
        height: auto; /* Maintain the aspect ratio of the image */
    }

    .site-title {
        font-size: 1rem; /* Adjust the font size for mobile */
    }

    .navigation, .pro-button {
        order: 3; /* Change order to stack navigation and button below the title and logo */
    }

    .navigation {
        flex-direction: row; /* Keep navigation links in a row */
        flex-wrap: wrap; /* Allow items to wrap if space is insufficient */
    }

    .nav-link {
        flex: 1 1 auto; /* Allow navigation links to grow and take available space */
        padding: 8px; /* Adjust padding for touch targets */
    }

    .pro-button {
        flex: 0 1 auto; /* Do not allow the button to grow, making it take only as much space as it needs */
    }
}



/* Glistening effect */
.pro-button::after {
    content: '';
    position: absolute;
    top: -50%; 
    left: -50%;
    width: 200%; 
    height: 200%; 
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%); /* Light glistening effect */
    transform: rotate(30deg); /* Angle of the glistening effect */
    animation: glisten 6s infinite linear; /* Adjusted animation duration for less frequent glistening */
}

/* Keyframes for glistening animation */
@keyframes glisten {
    0% {
        transform: translateX(-50%) rotate(30deg);
    }
    100% {
        transform: translateX(150%) rotate(30deg);
    }
}


/* Responsive Adjustments for Mobile */
@media (max-width: 480px) {
    .header, .navigation, .nav-link {
        background-color: transparent; /* Set the background color to black */
    }

   
}
