/* Rodman.css */

.rodman-container {
    background-color: black; /* Dark mode background color */
    color: #E0E0E0; /* Soft white text color for readability */
    text-align: center;
    padding: 40px;
    border-radius: 10px; /* Rounded corners for modern feel */
    max-width: 800px;
    margin: 40px auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Subtle shadow for depth */
}

.rodman-logo {
    width: 250px; /* Adjust based on your actual logo size */
    height: auto;
    margin: 20px 0;
}

.rodman-container h2 {
    color: #0A84FF; /* iOS blue for headlines */
    margin-bottom: 20px;
}

.rodman-container p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 20px; /* Space after the paragraph */
}

.features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
    margin-bottom: 30px;
}

.feature {
    background: #2C2C2E;
    color: #E0E0E0;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.feature:hover {
    background-color: #3A3A3C; /* Slightly lighter background on hover for interactivity */
}

.notify-button {
    background-color: #0A84FF; /* iOS blue for the button */
    color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 20px; /* Rounded edges for the button */
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    outline: none;
    margin-top: 20px;
    }
    
    .notify-button:hover {
    background-color: #007AFF; /* A darker shade of iOS blue for hover state */
    outline: none;
    }
    
    /* Responsive Design /
    @media (max-width: 768px) {
    .features {
    grid-template-columns: 1fr; / Stacks the features on smaller screens */
    

    .feature {
        display: flex; /* Establishes flex container */
        justify-content: center; /* Centers content horizontally */
        align-items: center; /* Centers content vertically */
        height: 50px; /* Set a fixed height or adjust as needed */
        background: #2C2C2E;
        color: #E0E0E0;
        padding: 10px;
        border-radius: 5px;
        transition: background-color 0.3s ease;
        cursor: pointer; /* Optional, if you want it to feel like a clickable button */
        /* Add any additional styling such as margin or width as needed */
    }
    