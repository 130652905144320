/* General Styles */
/* General Styles */
body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #000000; /* Full black background */
    color: #FFFFFF; /* High contrast text color for readability */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* ... (rest of the CSS) ... */


/* Game List Container */
.game-list-container {
    max-width: 700px;
    margin: 20px auto;
    padding: 20px;
    background-color: #1C1C1E; /* Dark container for each game */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.5); /* Shadow for depth */
}

.game-list-title {
    font-size: 1.5rem; /* Adjusted for mobile screens */
    color: #FFFFFF; /* Pure white for the title */
    margin-bottom: 20px;
    justify-content: center;
    text-align: center; /* Center align text */

}

/* Filters */
.filters {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    row-gap: 10px; /* Adds spacing between rows when wrapped */
}

.filters input[type="date"],
.filters input[type="text"] {
    padding: 10px;
    border-radius: 10px;
    background-color: #2C2C2E;
    border: 1px solid #3A3A3C;
    color: #FFFFFF;
    font-size: 0.9rem;
}

/* List Group */
.list-group {
    list-style: none;
    margin: 0;
    padding: 0;
}

.list-group-item {
    background-color: #1C1C1E; /* Slightly lighter dark for contrast */
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Score Container */
.score-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    width: 100%;
}

.team-name {
    font-size: 1rem; /* Smaller font size for team names */
    font-weight: bold;
    color: black; /* Bright text for team names */
}

.game-score {
    padding: 8px 20px; /* Adjusted padding for score container */
    background-color: #007AFF; /* iOS blue for score background */
    color: #FFFFFF; /* White for score text */
    border-radius: 20px; /* Rounded corners for the score background */
    font-size: 1.2rem; /* Larger font size for scores */
    font-weight: bold;
    margin: 5px 0; /* Margin for spacing around score */
}

.game-date {
    font-size: 0.8rem; /* Smaller font size for the date */
    color: #A8A8A8; /* Grey for less emphasis on the date */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .game-list-container {
        padding: 15px; /* Slightly less padding on tablets */
    }

    .filters input[type="date"],
    .filters input[type="text"] {
        font-size: 0.85rem; /* Slightly smaller font size on tablets */
    }

    .team-name,
    .game-score {
        font-size: 1rem; /* Adjusted font size for team names and scores on tablets */
    }
}

@media (max-width: 480px) {
    .game-list-container {
        margin: 10px auto;
        padding: 10px; /* Reduced padding on mobile for more space */
    }

    .game-list-title {
        font-size: 1.25rem; /* Smaller title for very small screens */
        text-align: center; /* Center align text */

    }

    .game-score {
        font-size: 1rem; /* Smaller font size for scores on mobile */
        padding: 6px 15px; /* Reduced padding on mobile */
    }

    .team-name {
        font-size: 0.9rem; /* Further reduction for team names on mobile */
    }

    .game-date {
        font-size: 0.75rem; /* Smaller font for date on mobile */
    }
}
.team-name {
    font-size: 1rem; /* Smaller font size for team names */
    font-weight: bold;
    color: black; /* Bright text for team names */
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.pagination button {
    background-color: #2C2C2E;
    color: #E0E0E0;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination button:hover {
    background-color: #007AFF;
}

.pagination button.active {
    background-color: #007AFF;
    color: #FFFFFF;
    font-weight: bold;
}


/* ... (existing styles) */

/* Date Navigation Buttons */
.date-nav {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 10px;
}

/* Score Toggle Button */
.score-toggle {
    background-color: #333; /* Dark buttons */
    color: #FFFFFF;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-left: 10px; /* Spacing from the filter input */
    transition: background-color 0.3s;
    justify-content: center;
}

.score-toggle:hover {
    background-color: #007AFF;
}

/* Score Placeholder Styling */
/* Score Placeholder Styling */
.game-score-hidden {
    background: linear-gradient(to right, #007AFF, #0051a8, #007AFF);
    background-size: 200% auto;
    border-radius: 20px;
    display: inline-block;
    width: 60px; /* Adjust width as needed */
    height: 24px; /* Adjust height as needed */
    animation: shine 2s linear infinite;

}

@keyframes shine {
    to {
        background-position: 200% center;
    }
}
/* Date Navigation Buttons */
.date-nav {
    background-color: #2C2C2E; /* Button background */
    border: none;
    color: #FFFFFF; /* Button text color */
    font-size: 1.2rem; /* Smaller font size */
    cursor: pointer;
    padding: 8px 12px; /* Smaller padding to reduce button size */
    margin: 0 5px; /* Space between buttons */
    border-radius: 15px; /* Adjusted border-radius for slightly less rounded corners */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
}

.date-nav:hover {
    background-color: #007AFF; /* Blue background on hover */
    color: #FFFFFF; /* Text color white on hover */
}

/* Adjustments for mobile responsiveness if needed */
@media (max-width: 768px) {
    .date-nav {
        padding: 6px 10px; /* Even smaller padding for mobile */
        font-size: 1rem; /* Smaller font size for mobile */
    }
}


/* Center "Games" title in mobile */
@media (max-width: 480px) {
    .game-list-title {
        text-align: center;
    }
}



/* Existing CSS styles ... */

/* Date Filter Styles */
.date-filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.date-nav-button {
    background-color: #2C2C2E;
    color: #FFFFFF;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.date-nav-button:hover {
    background-color: #007AFF;
    color: #FFFFFF;
}

.date-input {
    padding: 10px;
    border-radius: 10px;
    background-color: #2C2C2E;
    border: 1px solid #3A3A3C;
    color: #FFFFFF;
    font-size: 0.9rem;
    flex: 1;
}

/* ... (other CSS styles) */
/* Date navigation container */
.date-nav-container {
    display: flex;
    overflow-x: auto; /* Allows horizontal scrolling */
    gap: 8px; /* Spacing between items */
    padding: 10px; /* Space inside the container */
    background-color: #000; /* Dark background */
}

/* Individual date button */
.date-nav-button {
    flex: 0 0 auto; /* Don't grow, don't shrink, basis is auto */
    background-color: #333; /* Dark buttons */
    color: #fff; /* White text */
    border: none; /* No border for a flat design */
    padding: 8px 12px; /* Padding inside the buttons */
    border-radius: 10px; /* Rounded corners */
    font-size: 0.9rem; /* Font size similar to the one in the screenshots */
    transition: background-color 0.3s; /* Smooth transition for hover effects */
}

/* Highlighted date button for the current day */
.date-nav-button.active {
    background-color: #007AFF; /* iOS blue for the active date */
    color: #fff; /* White text for the active date */
}

/* Hover effect for buttons */
.date-nav-button:hover {
    background-color: #007AFF; /* iOS blue for hover */
    color: #fff; /* White text for hover */
}

/* Ensure that text is not selectable for a better touch experience */
.date-nav-button {
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    user-select: none; /* Standard syntax */
}

/* Add smooth scrolling for touch devices */
.date-nav-container {
    -webkit-overflow-scrolling: touch;
}

/* Responsive adjustments if needed */
@media (max-width: 480px) {
    /* Adjust font sizes and padding for smaller screens if necessary */
    .date-nav-button {
        font-size: 0.8rem; /* Smaller font size on mobile */
        padding: 6px 10px; /* Smaller padding on mobile */
    }
}


.logo-and-title{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px
}

/* League Filter Dropdown Styling */
.league-filter {
    position: relative;
    display: flex;
    justify-content: center; /* This will center the dropdown if it's not full width */
    margin-bottom: 20px; /* Space below the filter */

}

.league-filter select {
    width: 100%; /* Full width to match other inputs */
    padding: 10px;
    border-radius: 10px;
    background-color: #2C2C2E;
    border: 1px solid #3A3A3C;
    color: #FFFFFF;
    font-size: 0.9rem;
    cursor: pointer; /* Indicates it's clickable */
    appearance: none; /* Removes default style on some browsers */
    outline: none; /* Removes the outline */
    max-width: 300px; /* Or whatever maximum width makes sense for your design */
    padding-right: 30px; /* Increase padding to make space for the arrow */

}


/* Hover effect for dropdown */
.league-filter select:hover {
    background-color: #3A3A3C;
}

/* League Badge Styling */
.league-badge {
    display: inline-block;
    padding: 4px 8px;
    background-color: #007AFF; /* iOS blue for league background */
    color: #FFFFFF; /* White for league text */
    border-radius: 12px; /* Rounded corners for the badge */
    font-size: 0.8rem; /* Smaller font size for the badge */
    margin: 4px 0; /* Margin for spacing */
    font-weight: bold;
    text-transform: uppercase; /* Uppercase for better badge visibility */
    text-align: center;
}


/* Adjustments for mobile responsiveness */
@media (max-width: 768px) {
    .league-filter select {
        padding-right: 40px; /* Increase padding for mobile to make space for the arrow */
    }


}



.prediction-details {
    margin-top: 10px;
    text-align: center;
}

.predicted-score {
    font-size: 0.9rem;
    font-weight: bold;
    color: #FFD700; /* Gold for emphasis */
}

.prediction-probabilities {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 5px;
    font-size: 0.8rem;
}

.probability {
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #2C2C2E; /* Dark background for contrast */
    color: #FFFFFF; /* White text */
}

.probability.win {
    background-color: #007AFF; /* Blue for win probability */
}

.probability.draw {
    background-color: #A8A8A8; /* Grey for draw probability */
}

.probability.lose {
    background-color: #FF3B30; /* Red for lose probability */
}

/* General Card Styling */
.list-group-item {
    background-color: #1E1E1E; /* Dark background for contrast */
    margin-bottom: 15px;
    padding: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6); /* Subtle shadow for depth */
}

.team-and-score {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.team-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: #FFFFFF; /* White for better contrast */
}

.game-score {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007AFF; /* Bright blue for the score */
    margin: 0 15px;
    background: rgba(0, 122, 255, 0.1); /* Light blue background */
    padding: 8px 16px;
    border-radius: 8px;
}

.prediction-probabilities.compact {
    display: flex;
    justify-content: space-around; /* Space items evenly */
    gap: 10px; /* Add space between probabilities */
    margin-top: 10px;
    text-align: center;
    font-size: 0.9rem;
}

.probability {
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 8px;
    color: #FFFFFF; /* White text for better readability */
}

.probability.win {
    background-color: #007AFF; /* Blue for home team win */
}

.probability.draw {
    background-color: #A8A8A8; /* Gray for draw */
}

.probability.lose {
    background-color: #FF3B30; /* Red for away team win */
}

.game-date {
    font-size: 0.9rem;
    color: #A8A8A8; /* Subtle gray for the date */
    margin-top: 10px;
}

.league-badge {
    background-color: #007AFF;
    color: #FFFFFF;
    padding: 5px 10px;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 12px;
    text-transform: uppercase;
    margin-top: 10px;
}

.team-and-score {
    display: flex;
    align-items: center; /* Vertically center content */
    justify-content: space-between; /* Evenly distribute space */
    width: 100%; /* Ensure the container spans the full width */
    margin-bottom: 10px;
}

.team-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: #FFFFFF; /* White for better contrast */
    flex: 1; /* Allow team names to take up equal space */
    text-align: center; /* Align text to the center */
}

.game-score {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007AFF; /* Bright blue for the score */
    background: rgba(0, 122, 255, 0.1); /* Light blue background */
    padding: 8px 16px;
    border-radius: 8px;
    text-align: center; /* Center align score */
    width: 80px; /* Fixed width to ensure consistent alignment */
}

@media (max-width: 768px) {
    .game-score {
        width: 60px; /* Smaller width for mobile screens */
    }
}


/* Game List Container Styling */
.game-list-container {
    max-width: 700px;
    margin: 20px auto;
    padding: 20px;
    background-color: #1C1C1E; /* Dark iOS-style container */
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6); /* Subtle shadow for depth */
}

/* Game Card Styling */
.list-group-item {
    background-color: #2C2C2E; /* Slightly lighter dark for contrast */
    margin-bottom: 15px;
    padding: 20px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4); /* Subtle card shadow */
}

/* Team and Score Styling */
.team-and-score {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space teams and score evenly */
    width: 100%;
    margin-bottom: 10px;
}

.team-name {
    font-size: 1.1rem;
    font-weight: 600;
    color: #FFFFFF; /* Clean white text for team names */
    text-align: center;
}

.game-score {
    font-size: 1.5rem;
    font-weight: bold;
    color: #FFFFFF;
    background: linear-gradient(145deg, #007AFF, #0051A8); /* iOS-style gradient */
    border-radius: 12px; /* Rounded score container */
    padding: 6px 16px;
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Subtle shadow on the score */
}

/* Prediction Details Styling */
.prediction-details {
    margin-top: 12px;
    text-align: center; /* Center-align content */
    font-size: 0.9rem;
}

.predicted-score {
    color: #FFD700; /* Gold color for predicted score */
    font-weight: bold;
    margin-bottom: 8px;
}

/* Probabilities Styling */
.prediction-probabilities {
    display: flex;
    justify-content: center; /* Center-align probabilities */
    gap: 15px; /* Spacing between probabilities */
    margin-top: 10px;
}

.probability {
    font-size: 0.9rem;
    font-weight: bold;
    padding: 5px 12px;
    border-radius: 12px; /* Rounded probabilities for iOS feel */
    color: #FFFFFF; /* White text */
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.probability.win {
    background-color: #007AFF; /* Blue for home team win */
}

.probability.draw {
    background-color: #A8A8A8; /* Gray for draw */
}

.probability.lose {
    background-color: #FF3B30; /* Red for away team win */
}

/* Date and Venue Styling */
.game-date,
.game-location {
    font-size: 0.8rem;
    color: #A8A8A8; /* Subtle gray for less emphasis */
    margin-top: 6px;
}

/* League Badge Styling */
.league-badge {
    background: linear-gradient(145deg, #007AFF, #0051A8); /* iOS-style gradient */
    color: #FFFFFF;
    padding: 6px 12px;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 16px;
    text-transform: uppercase;
    margin-top: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Subtle shadow */
}

/* Hover Effects */
.list-group-item:hover {
    transform: scale(1.02); /* Slight zoom on hover */
    transition: transform 0.3s ease;
}

.date-nav-button:hover {
    background-color: #0051A8; /* Bright blue on hover */
    color: #FFFFFF;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .game-score {
        font-size: 1.2rem;
        padding: 5px 10px;
    }
    .probability {
        font-size: 0.8rem;
        padding: 4px 8px;
    }
    .league-badge {
        font-size: 0.8rem;
        padding: 5px 10px;
    }
}


/* Game Card Styling */
.game-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #1C1C1E; /* Dark background for contrast */
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5); /* Subtle shadow */
    padding: 12px 20px;
    margin-bottom: 15px;
    max-width: 600px;
    width: 100%;
}

/* Game Info Section */
.game-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.team {
    display: flex;
    align-items: center;
    gap: 8px;
}

.team-score {
    font-size: 1.4rem;
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 8px;
}

.win-prob {
    font-size: 1rem;
    font-weight: bold;
    color: #007AFF; /* Highlight color for win probability */
    background: rgba(0, 122, 255, 0.1); /* Light blue background */
    padding: 5px 10px;
    border-radius: 8px;
}

/* Divider */
.game-divider {
    height: 1px;
    background-color: #A8A8A8; /* Subtle gray divider */
    margin: 5px 0;
}

/* Ensure the game-details container is a flex column */
.game-details {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-end; /* Align elements to the right */
    margin-top: 5px; /* Add some spacing from the rest of the card */
}

/* Style the draw probability field */
.draw-prob {
    font-weight: bold;
    color: #FFD700; /* Gold for draw probability */
    font-size: 0.9rem; /* Consistent font size */
    margin-top: 5px; /* Add spacing between it and the above field */
}


.game-date {
    font-size: 0.75rem;
    color: #A8A8A8;
    text-align: right;
}

/* Hover Effect */
.game-card:hover {
    transform: scale(1.02); /* Slight zoom effect */
    transition: transform 0.3s ease-in-out;
}

/* Responsive Design */
@media (max-width: 768px) {
    .game-card {
        padding: 10px 15px;
    }
    .team-score {
        font-size: 1.2rem;
    }
    .win-prob {
        font-size: 0.85rem;
    }
}

/* Highlighting Favored Teams */
.favored {
    background-color: #007AFF; /* Blue for the favored team */
    color: #FFFFFF;
    font-weight: bold;
}

/* Game Info Styling */
.game-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

/* Win Probability Styling */
.win-prob {
    font-size: 1rem;
    padding: 5px 10px;
    border-radius: 8px;
    color: #FFFFFF;
    background: rgba(0, 122, 255, 0.1);
}


.team-score {
    font-size: 1.4rem;
    font-weight: bold;
    color: #FFFFFF;
}

.team-score::after {
    content: '?';
    font-weight: bold;
    color: #FFD700; /* Gold for hidden scores */
    visibility: hidden;
}

.team-score.hidden::after {
    visibility: visible;
}


/* Default Team Score Styling */
.team-score {
    font-size: 1.4rem;
    font-weight: bold;
    color: #FFFFFF;
    display: inline-block;
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth animation */
}

/* Hidden Scores */
.team-score.hidden {
    opacity: 0; /* Fade out */
    transform: scale(0.8); /* Shrink slightly */
}

/* When Scores are Visible */
.team-score {
    opacity: 1; /* Fully visible */
    transform: scale(1); /* Default size */
}

/* Default Team Score Styling */
.team-score {
    font-size: 1.4rem;
    font-weight: bold;
    color: #FFFFFF;
    display: inline-block;
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth animation */
}

/* Hidden Scores */
.team-score.hidden {
    opacity: 0; /* Fade out */
    transform: scale(0.8); /* Shrink slightly */
}

/* When Scores are Visible */
.team-score {
    opacity: 1; /* Fully visible */
    transform: scale(1); /* Default size */
}

/* Optional Slide Transition */
.team-score.hidden {
    transform: translateY(-10px); /* Slide upwards */
}

/* Favored Probabilities */
.favored {
    background-color: #007AFF; /* Highlight color */
    color: #FFFFFF;
    font-weight: bold;
}


.team-score.hidden {
    opacity: 0;
    transform: translateY(-10px); /* Slide upwards */
}

.team-score {
    opacity: 1;
    transform: translateY(0); /* Reset position */
}


.win-prob-container {
    display: flex;
    flex-direction: column; /* Stack "Prob" and percentage vertically */
    align-items: center; /* Center-align both */
    margin-top: 5px; /* Add some spacing from the team score */
}

.prob-label {
    font-size: 0.7rem; /* Smaller font size for "Prob" */
    color: #A8A8A8; /* Light gray for a subtle look */
    margin-bottom: 2px; /* Spacing between "Prob" and percentage */
}

.win-prob {
    font-weight: bold;
    font-size: 1rem; /* Larger font size for percentage */
}



@media (max-width: 480px) {
    .filters {
        display: flex;
        flex-wrap: wrap; /* Allow wrapping */
        gap: 10px; /* Add spacing between elements */
    }

    /* Exclude date picker and navigation buttons from stacking */
    .date-filter {
        display: flex; /* Keep them in a single row */
        align-items: center; /* Vertically align content */
        gap: 5px; /* Space between date and buttons */
    }

    .date-filter input[type="date"] {
        flex: 1; /* Allow the date picker to take up available space */
    }

    /* Stack other filters vertically */
    .filters input[type="text"],
    .filters .score-toggle,
    .league-filter {
        width: 100%; /* Make them take full width */
        max-width: 100%; /* Prevent overflow */
    }
}

@media (max-width: 480px) {
    .date-filter {
        display: flex;
        flex-direction: row; /* Keep everything in one row */
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        gap: 8px; /* Add spacing between the buttons and the date picker */
        width: 100%; /* Ensure it spans the container */
        margin: 0 auto; /* Center the entire block */
    }

    .date-filter input[type="date"] {
        text-align: center; /* Center-align the text inside */
        max-width: 150px; /* Limit the width of the date input */
        width: auto; /* Allow the input to resize appropriately */
    }

    .date-nav-button {
        padding: 5px 10px; /* Ensure consistent button size */
        font-size: 1rem; /* Standardize font size */
        flex: 0; /* Prevent stretching */
    }
}

.team-badge {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    border-radius: 50%; /* Optional: make badges circular */
    object-fit: cover; /* Ensure images fit within the container */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: add a slight shadow */
}

.league-name {
    font-size: 0.75rem;
    color: #A8A8A8; /* Subtle gray text */
    position: absolute; /* Use absolute positioning for precise placement */
    bottom: 8px; /* Distance from the bottom */
    left: 10px; /* Distance from the left */
    text-align: left;
    display: block;
}

.game-card {
    position: relative; /* Ensure the card is a positioning context for absolute elements */
}

