body {
    background-color: black;
    color: #E0E0E0; /* Ensure default text is readable on the dark background */
    margin: 0; /* Remove default margin */
    font-family: Arial, sans-serif; /* Optional: Set a default font for consistency */
}

/* Home Container */
.home-container {
    background-color: black; /* Dark mode background color */
    color: #E0E0E0; /* Soft white text color for readability */
    text-align: center;
    padding: 40px;
    border-radius: 10px; /* Rounded corners for modern feel */
    max-width: 800px;
    margin: 40px auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Optional: subtle shadow for depth */
}

/* Logo */
.home-logo {
    width: 200px; /* Adjust based on your actual logo size */
    height: auto;
    margin-bottom: 20px;
}

/* Headings */
.home-container h1 {
    color: #FFFFFF; /* Pure white for headings for contrast */
    margin-bottom: 20px;
}

/* Paragraph Text */
.home-container p {
    font-size: 1rem;
    line-height: 1.5;
    color: #BDBDBD; /* Light grey for paragraph text */
    margin-bottom: 20px; /* Space after the paragraph */
}

/* Responsive Design */
@media (max-width: 600px) {
    .home-logo {
        width: 50%; /* Responsive width for mobile devices */
    }

    .home-container h1 {
        font-size: 1.5rem; /* Adjust heading size for mobile */
    }

    .home-container p {
        font-size: 0.9rem; /* Adjust paragraph size for mobile */
    }
}
